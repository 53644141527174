<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RS Code</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="rscode.rs_code"
                  placeholder="Enter RS Code"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RS Name (Eng)</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="rscode.rs_name"
                  placeholder="Enter RS Name (Eng)"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Address 1</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="rscode.address1"
                  placeholder="Enter Address 1"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Address 2</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="rscode.address2"
                  placeholder="Enter Address 2"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Contact No</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="rscode.contact"
                  placeholder="Enter Contact No"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RO Code</label>
              <select required class="form-control" v-model="rscode.revenue_officer_id" id="">
                <option v-for="rocodeList in rocodeLists" :value="rocodeList.id">{{ rocodeList.ro_name }}</option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "CreateRSCode",
  props: {
    rocodeLists: {
      type: Array,
      default: []
    }
  },
  data: () => {
    return {
      showToast: false,
      toastr: "",
      rscode: {
        rs_code: "",
        rs_name: "",
        address1: "",
        address2: "",
        contact: "",
        revenue_officer_id: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
          .dispatch("RSCode/storeRSCode", this.rscode)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "RSCode successfully created.", "RSCode Created");
            setTimeout(() => {
              this.$emit("onSavedRSCode");
            }, 350);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            this.showToast = true;
            for (let field of Object.keys(errors)) {
              this.$toastr.e("error", errors[field][0], "Error!");
            }
          });
    },
  }
};
</script>

<style scoped>
</style>
